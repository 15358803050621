﻿@import "variables";

.center {
    text-align: center;
}

.v-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.no-margin {
    margin: 0 0 0 0;
}

.cursor-pointer{
    cursor: pointer;
}

.fade{
    transition: opacity 0.3s ease-in-out;
}

.hide{
    opacity: 0 !important;
}

.show{
    opacity: 1 !important;
}