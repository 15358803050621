﻿@import "variables";
@import "typography";



button.button, a.button, label.button {
    @extend .font-medium;
    @extend .font-size-body;
    background: var(--solidButtonColor);
    display: inline-block;
    text-align: center;
    line-height: 3em;
    height: 3em;
    padding: 0 1em 0 1em;
    text-transform: capitalize;
    margin: 1em 1em 0 0;
    transition: all 0.25s ease-in-out;
    border: 0 none;
    border-radius: 1.5em;
    color: var(--solidButtonTextColor);
    cursor: pointer;

    &.spinner {
        position: relative;

        & > i {
            display: none;
            font-size: 1.5em;
            line-height: 1.5em;
            height: 1.5em;
            width: 1.5em;
            position: absolute;
            top: 0.25em;
            left: 50%;
            margin-left: -0.625em;
        }

        & > span {
            visibility: visible;
        }

        &.active {
            & > i {
                display: block;
            }

            & > span {
                visibility: hidden;
            }
        }
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    &:disabled {
        background-color: var(--disabledButtonColor);
        cursor: default;
    }

    &.button-large {
        padding: 0 3em 0 3em;
    }

    &.plain {
        border-radius: 1.5em;
        border: 2px solid var(--solidButtonColor);
        color: var(--solidButtonColor);
        background: transparent;
        line-height: 2.75em;
    }

    &.outline {
        height: 3em;
        border-radius: 0.5em;
        border: 2px solid var(--solidButtonColor);
        color: var(--solidButtonColor);
        background: transparent;
        line-height: 2.75em;
    }

    &.rounded-button {
        border-radius: 50px;
    }

    &.borderless {
        color: var(--solidButtonColor);
        background: transparent;
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 0;
        position: relative;
        height: auto;
        line-height: $line-height-normal;
        text-decoration: none;
        z-index: 1;

        &::before {
            position: absolute;
            bottom: 0.0625em;
            left: 0;
            content: "";
            width: 100%;
            height: 0;
            border-bottom: 1px solid var(--solidButtonColor);
            z-index: -1;
            transition: all 0.25s ease-in-out;
        }
    }

    &.wide {
        width: 100%;
    }
}

a.label-button.button {
    min-width: 12em;
    border-radius: 50px;
}

/* Input fields */
.input {
    position: relative;
    display: inline-block;
    margin: 0 0 .85em 0;
    width: 100%;
    @extend .font-body-copy;
}

.input-field {
    @extend .font-body-copy;
    position: relative;
    display: block;
    float: right;
    padding: 1.2em;
    border-radius: 3px;
    color: var(--inputTextColorColor);
    line-height: 1.5em;
    min-height: 3em;
    -webkit-appearance: none; /* for box shadows to show on iOS */
    -moz-appearance: none;
    width: 100%;
    background-color: var(--inputBackgroundColor);
    transition: all 0.25s;
    overflow: hidden;

    &:focus {
        outline: none;
    }
}

.Tags {
    .input-field {
        padding: 0;
    }

    .ti-input {
        border-radius: 3px;
        min-height: 3em;
    }

    .ti-tag {
        background-color: var(--svgBaseColor) !important;
    }
}

input.input-field {
    height: 3em;
    border: 1px solid var(--borderColor);
    box-shadow: none;
    outline: 0;

    &[readonly] {
        background: #efefef;
        cursor: no-drop;
    }
}

    .input.input-checklist {
        display: block;
        margin-top: 0.5em;
        margin-bottom: 3.5em;

        & > .checkbox {
            margin-bottom: 0.25em;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    textarea.input-field {
        resize: none;
        height: auto;
        border: 1px solid var(--borderColor);
        box-shadow: none;
        outline: 0;
        overflow: auto;
    }

    select.input-field {
        border: 1px solid var(--borderColor);
        box-shadow: none;
        outline: 0;
        -webkit-appearance: menulist;
        -webkit-appearance: none;
        -moz-appearance: none;
        @extend .font-body-copy;
        padding: 0.75em 2.5em 0.75em 0.5em;
        background-image: url("/img/arrow.svg.svgz");
        background-position: right 0.5em center;
        background-size: 1.5em 1.5em;
        background-repeat: no-repeat;
        transition: all 0s;
        height: 3em;
        cursor: pointer;
    }

    select::-ms-expand {
        display: none;
    }

    select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
        transition: none;
    }

    .input-label {
        @extend .font-body-copy;
        display: inline-block;
        float: right;
        color: var(--inputTextColor);
        line-height: 1.5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 100%;
        text-align: left;
        position: absolute;
        bottom: 100%;
        pointer-events: none;
        overflow: hidden;
        padding: 0 0.5em;
        transform: translate3d(0, 2.25em, 0);
        transition: all 0.25s;
        transition-timing-function: ease-in-out;
        margin: 0;

        &.static {
            transform: translate3d(0, 0, 0);
            transition: none;
            color: var(--textColor);
            pointer-events: auto;
        }
    }

    .input-label-content {
        position: relative;
        display: block;
        width: 100%;
        color: var(--inputTextColor);
        padding: 0;
        transition: all 0.25s;
        transition-timing-function: ease-in-out;

        &::after {
            content: attr(data-content);
            position: absolute;
            bottom: 100%;
            left: 0;
            height: 100%;
            width: 100%;
            color: var(--textColor);
        }
    }

    .input-field[required] ~ .input-label, .input-field[data-val-required] ~ .input-label {
        & > .input-label-content {
            &::after {
                content: attr(data-content) " *";
            }
        }
    }

    .input-validation, .field-validation-error {
        color: var(--inputValidationColor);
        opacity: 0;
        transition: all 0.25s;
        white-space: nowrap;
    }

    .input:not(.input-filled) {
        input:invalid, textarea:invalid, .input-field.input-validation-error {
            & ~ label {
                visibility: visible;
            }
        }

        .input-validation, .field-validation-error {
            opacity: 1;
        }
    }

    .input.input-filled {
        input:invalid, textarea:invalid, .input-field.input-validation-error {
            & ~ label {
                visibility: visible;
            }
        }

        .input-validation, .field-validation-error {
            opacity: 1;

            & > span {
                visibility: hidden;

                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .input-validation > span {
        display: inline-block;
        float: right;
        color: var(--inputValidationColor);
        line-height: 1.5em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-touch-callout: none;
        user-select: none;
        width: 100%;
        text-align: left;
        position: absolute;
        bottom: 100%;
        pointer-events: none;
        padding: 0 0.5em;
        transform: translate3d(0, 2.25em, 0);
        transition: transform 0.25s;
        transition-timing-function: ease-in-out;

        &::after {
            @extend .font-bold;
            content: "!";
            border-radius: 50%;
            background: var(--inputValidationColor);
            color: var(--inputBackgroundColor);
            position: absolute;
            float: right;
            font-weight: normal;
            bottom: 0;
            right: 0.75em;
            height: 100%;
            font-size: 1em;
            line-height: 1.5em;
            min-width: 1.5em;
            height: 1.5em;
            text-align: center;
        }
    }

    .input-field:focus + .input-label,
    .input-filled .input-label,
    .input-field.input-validation-error + .input-label {
        transform: translate3d(0, 0, 0);
    }

    .input-field:focus + .input-label .input-label-content,
    .input-filled .input-label-content,
    .input-field:invalid + .input-label .input-label-content, .input-field.input-validation-error + .input-label .input-label-content {
        transform: translate3d(0, 100%, 0);
    }

    .input-checkbox {
        display: block;
        height: 3em;
        padding: 0;

        input[type=checkbox] {
            position: absolute;
            vertical-align: top;
            //top: -9999px;
            left: -9999px;
            -webkit-appearance: none;
            width: 2em;
            -webkit-tap-highlight-color: rgba(0,0,0,0);

            ~ label {
                @extend .font-size-body;
                display: inline-block;
                position: relative;
                padding-left: 2.5em;
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;
                user-select: none;
                color: var(--svgBaseColor);
                line-height: 2em;
                height: 2em;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: ' ';
                    width: 2em;
                    height: 2em;
                    min-width: 2em;
                    min-height: 2em;
                    max-width: 2em;
                    max-height: 2em;
                    border: 1px solid var(--borderColor);
                    border-radius: 10%;
                    background-color: white;
                    transition: all 0.25s ease-in-out;
                }
            }

            &:checked ~ label::before {
                content: '\2713';
                color: var(--svgBaseColor);
                text-align: center;
                line-height: 2em;
                background: white;
            }
        }
    }

    .input-radio {
        display: block;
        height: 3em;
        padding: 0;

        input[type=radio] {
            position: absolute;
            vertical-align: top;
            //top: -9999px;
            left: -9999px;
            -webkit-appearance: none;
            width: 2em;
            -webkit-tap-highlight-color: rgba(0,0,0,0);

            ~ label {
                @extend .font-size-body;
                display: inline-block;
                position: relative;
                padding-left: 2.5em;
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                -moz-user-select: none;
                -ms-user-select: none;
                -webkit-user-select: none;
                user-select: none;
                color: var(--inputTextColor);
                line-height: 2em;
                height: 2em;

                &::before {
                    position: absolute;
                    top: 7px;
                    left: 11px;
                    content: ' ';
                    width: 2em;
                    height: 2em;
                    min-width: 2em;
                    min-height: 2em;
                    max-width: 2em;
                    max-height: 2em;
                    border: 1px solid var(--borderColor);
                    border-radius: 50%;
                    background-color: var(--inputBackgroundColor);
                    transition: all 0.25s ease-in-out;
                }
            }

            &:checked ~ label::before {
                content: '';
                text-align: center;
                line-height: 2em;
                background: var(--svgBaseColor);
                box-shadow: inset 0 0 0 0.25em var(--inputBackgroundColor);
            }
        }
    }

    .c360-input-form {
        border: 0;
        margin: 0;
        padding: 0;

        .c360-row {
            clear: both;
            float: none;
            width: 100%;
            padding: 0;

            .c360-col {
                padding: 0 1rem 0 1rem;
            }
        }

        .buttons {
            text-align: center;
            margin-bottom: 1em;

            & > button:last-child {
            }
        }
    }

    .c360-row.c360-two-col {
        clear: both;
        float: none;

        .c360-col {
            float: left;
            width: 50%;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .c360-row.one-col {
        .c360-col {
            width: 100%;
            clear: both;
            float: none;
            padding-left: 0;
            padding-right: 0;
        }
    }

    p.input-form-result {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: none;
        @extend .font-regular;
        color: var(--textColor);
    }

    .box-form {
        max-width: 30rem;
        min-width: 14rem;
        margin: 2em auto 2em auto;
        position: relative;
        padding: 2rem 2rem 2rem 2rem;
        color: var(--loginTextColor);

        & > img {
            height: 4em;
            display: block;
            margin: 0 auto 2em auto;
        }

        .input-label-content {
            padding: 0;
        }

        h1 {
            margin: 0 0 2em 0;
            @include fluid-type(14px, 18px);
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.125em;
            color: var(--loginTextColor);
        }

        &.smallbutton {
            color: var(--loginTextColor);
        }

        p {
            margin-top: 1em;
        }
    }

    .validation-summary-valid {
        display: none;
    }

    .validation-summary-errors {
        display: block;
        margin-bottom: 3em;
        color: var(--inputValidationColor);
        padding: 0.5em 1em 0.5em 1em;
        border: 1px solid var(--inputValidationColor);

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-indent: 0;

            li {
                text-indent: 0;
                padding: 0;
                margin: 0.5em 0 0.5em 0;
            }
        }
    }

    .toggle {
        span.input {
            width: $toggleWidth;
            height: $toggleHeight;
            margin: 0;
            padding: 0;

            input[type='checkbox'] {
                width: 100%;
                height: 100%;
                display: none;

                ~ label {
                    position: relative;
                    top: 0;
                    left: 0;
                    background: $toggleInactiveBG;
                    width: 100%;
                    height: 100%;
                    border-radius: $toggleHeight;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: $toggleSpacing;
                        height: $toggleHeight - 2 * $toggleSpacing;
                        width: $toggleHeight - 2 * $toggleSpacing;
                        background: $toggleButtonBG;
                        content: '';
                        border-radius: 100%;
                        transform: translateY(-50%);
                        box-shadow: 0 0.125em 0.125em #444;
                        transition: all 0.3s ease-in-out;
                    }
                }

                &:checked {
                    & ~ label {
                        background: var(--menuSidebarBg);

                        &:before {
                            left: $toggleWidth - $toggleSpacing - ($toggleHeight - 2 * $toggleSpacing);
                        }
                    }
                }
            }
        }
    }

    .sidebar {
        .field:first-child {
            margin-top: 20px;
        }

        .Media {
            .field-wrapper {
                border-top: 0;
            }
        }
    }

    .field-wrapper {
        margin: 0;
        width: 100%;

        div.no-padding, &.no-padding {
            padding: 0;
            position: static;
        }

        div.center {
            align-self: center;
        }

        div.content-right {
            text-align: right;
        }

        .sidebar & {
            border-top: 0.0625em solid rgba(0,0,0,0.05);
            padding: 1em 3em;
        }

        &.appear-in {
            border-top: 0;
        }

        &.dependency {
            border-top: none;
            padding-top: 0;

            &-toshow {
                transform: translateY(-100%);
                opacity: 0;
            }

            &-tohide {
            }
        }

        &.toggle {
            p {
                margin: 0;
            }
        }

        .datetime-wrapper {
            position: relative;
            height: 3em;
            float: left;
            width: 100%;
        }

        .datetime-icon {
            @extend .font-body-copy;
            position: absolute;
            top: 1.5em;
            right: 0.375em;
            height: auto;
            width: 2.25em;
            padding: 0 0.375em;
            transform: translateY(-50%);
            pointer-events: none;
            cursor: pointer;
        }

        .picklist {
            position: relative;

            .select-wrapper {
                position: relative;
                float: left;
                width: 100%;

                .sidebar & {
                    margin-bottom: 0.5em;
                }
            }

            select {
                background: var(--inputBackgroundColor);
            }

            .select-arrow {
                @extend .font-body-copy;
                position: absolute;
                top: 1.5em;
                right: 0.375em;
                height: auto;
                width: 2.25em;
                padding: 0 0.375em;
                transform: translateY(-50%);
                pointer-events: none;
            }
        }

        .dragdrop {
            &.media-uploader {
                border-top: none;
                border: 0.125em dashed var(--inputFileBorderColor);
                background: var(--inputFileBackgroundColor);
                text-align: center;
                padding-top: 2em;
                padding-bottom: 3em;
                background: white;

                legend {
                    text-align: center;
                    margin: 1rem 0;
                }

                p {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 1em;
                }

                label {
                    min-width: 12em;
                    margin: 0;
                    margin-bottom: 1rem;
                }

                input[type=file] {
                    width: 1px;
                    height: 1px;
                    visibility: hidden;
                    position: absolute;
                    z-index: -1;
                }

                & *:last-child {
                    margin-bottom: 1rem;
                }

                &.dragover {
                    outline-color: var(--hightlightColor);
                }
            }

            input {
                outline: none !important;
            }

            .media-icon {
                width: 30%;
                max-width: 8em;
                margin: 1em auto;

                svg path {
                    fill: var(--svgBaseColor);
                }
            }
        }
    }

    .form-actions {
        .actions-header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 1.5em;
            padding: 0 0.5em;

            > * {
                flex: 1 0 50%;
            }
        }

        .actions-footer {
            text-align: center;

            a {
                color: var(--textColor);
                text-decoration: underline;
                margin: 0 0.75em;
                @extend .font-size-body-small;
                @extend .font-medium;

                &.text-caution {
                    color: var(--hightlightColor);
                }
            }
        }
    }

    .datetimepicker table tr td.active:hover, .datetimepicker table tr td.active:hover:hover, .datetimepicker table tr td.active.disabled:hover, .datetimepicker table tr td.active.disabled:hover:hover, .datetimepicker table tr td.active:active, .datetimepicker table tr td.active:hover:active, .datetimepicker table tr td.active.disabled:active, .datetimepicker table tr td.active.disabled:hover:active, .datetimepicker table tr td.active.active, .datetimepicker table tr td.active:hover.active, .datetimepicker table tr td.active.disabled.active, .datetimepicker table tr td.active.disabled:hover.active, .datetimepicker table tr td.active.disabled, .datetimepicker table tr td.active:hover.disabled, .datetimepicker table tr td.active.disabled.disabled, .datetimepicker table tr td.active.disabled:hover.disabled, .datetimepicker table tr td.active[disabled], .datetimepicker table tr td.active:hover[disabled], .datetimepicker table tr td.active.disabled[disabled], .datetimepicker table tr td.active.disabled:hover[disabled],
    .datetimepicker table tr td span.active:hover, .datetimepicker table tr td span.active:hover:hover, .datetimepicker table tr td span.active.disabled:hover, .datetimepicker table tr td span.active.disabled:hover:hover, .datetimepicker table tr td span.active:active, .datetimepicker table tr td span.active:hover:active, .datetimepicker table tr td span.active.disabled:active, .datetimepicker table tr td span.active.disabled:hover:active, .datetimepicker table tr td span.active.active, .datetimepicker table tr td span.active:hover.active, .datetimepicker table tr td span.active.disabled.active, .datetimepicker table tr td span.active.disabled:hover.active, .datetimepicker table tr td span.active.disabled, .datetimepicker table tr td span.active:hover.disabled, .datetimepicker table tr td span.active.disabled.disabled, .datetimepicker table tr td span.active.disabled:hover.disabled, .datetimepicker table tr td span.active[disabled], .datetimepicker table tr td span.active:hover[disabled], .datetimepicker table tr td span.active.disabled[disabled], .datetimepicker table tr td span.active.disabled:hover[disabled] {
        background: var(--textColor);
    }

    .fieldgroup-labels {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 1;

        .group-label {
            width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;

            .svg-wrapper {
                width: 100%;
                max-width: 4.5em;
                background: #c1c1c1;
                border-radius: 4.5em;
                padding: 1.125em;

                svg path {
                    fill: #fff;
                }
            }

            &.active {
                .svg-wrapper {
                    background: var(--svgBaseColor);
                }
            }

            h3 {
                text-align: center;
                margin: 0.75em 0;
            }

            & > div {
                width: 100%;
            }
        }
    }

    .wizard {
        .step {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .formtype-wizard {
        .form-section {
            margin-top: 50px;
            background: white;
            border-radius: 16px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 13px;
            padding: 40px 25px;
        }
    }

    .wizard-progress {
        position: absolute;
        width: 98%;
        height: 10px;
        z-index: -1;
        top: 33px;
        left: 2%;

        .grey-bar {
            position: absolute;
            width: 98%;
            height: 10px;
            background-color: #c1c1c1;
        }

        .completed-bar {
            position: absolute;
            width: 0%;
            height: 10px;
            background: var(--svgBaseColor);
            transition: all .5s linear;
        }
    }

    .Hidden {
        display: none;
    }

    .summary-field {
        display: flex;

        .field-wrapper {
            border-bottom: 1px solid #DBDBDB;
            margin-bottom: 10px;
            min-height: 76.5px;

            &.choice {
                display: flex;
                justify-content: center;

                .svg-wrapper {
                    width: 100%;
                    max-height: 20px;
                    align-self: flex-end;
                    margin-bottom: 10px;

                    svg {
                        max-height: 20px;

                        path {
                            fill: var(--svgBaseColor) !important;
                        }
                    }
                }
            }
        }

        &.media {
            display: block;
        }

        p {
            margin-bottom: 0.25em;
        }
    }

    .summary {
        .summary-field:last-child {
            .field-wrapper {
                border: 0;
                margin-bottom: 0;
            }
        }
    }
