﻿.fade-in{
    animation-name: fade-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
}

.appear-in{
    animation-name: appear-in;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.appear-out {
    animation-name: appear-out;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

@keyframes fade-in{
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes appear-in {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    60%{
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes appear-out {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }

    40%{
        opacity: 0;
    }

    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

@keyframes loader {
    0% {
        top: 6px;
        height: 51px;
    }

    50%, 100% {
        top: 19px;
        height: 26px;
    }
}
