﻿@import "variables";

html, body{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class^="col-"],
    & > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.create-header {
    display: flex;
    align-items: center;
    margin-bottom: 3em;

    > * {
        margin: 0;
    }
}