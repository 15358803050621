﻿@import "variables";
@import "utilities";

#command-center {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

#command-center {
    background: var(--workspaceBg);
}

#workspace {
    width: 100%;
    height: 100%;
    background: var(--workspaceBg);
    position: relative;
    padding: 5em 3em 3em 3em;
    transition: width 0.75s ease-in-out;
    overflow-y: auto;
}

#side-bar {
    background: var(--menuSidebarBg);
    color: var(--textColorContrast);
    width: 4em;
    height: 100%;

    &.open {
        width: 15%;
    }

    svg path {
        fill: var(--logoFill);
    }
}

#property-bar {
    background: var(--propertySidebarBg);
    color: var(--propertySidebarTextColor);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 25%;

    .sidebar-button {
        transform: rotateY(0deg);
    }

    &.open {
        .sidebar-button {
            transform: rotateY(180deg);
        }
    }

    svg path {
        fill: var(--svgBaseColor);

        &.combo {
            fill: var(--svgComboColor);
        }

        &.alt {
            fill: var(--svgAltColor);
        }
    }

    .sidebar-header {
        padding: 0 2em;
    }

    .sidebar-content {

        label.outline {
            border-radius: 50px;
        }

        div.field-wrapper {
            width: 100%;
            padding: 10px 30px;
            margin: 0 0;

            legend {
                margin-bottom: 0.35em;
            }

            .dragdrop {
                &.media-uploader {
                    background: var(--workspaceBg);
                }
            }
        }

        .form-section {
            box-shadow: none;
            margin: 0;
            padding: 0;
        }

        .field {
            padding: 0;
            position: static !important;

            p.field-description {
                font-size: 14px;
                margin: 0px;
                line-height: 1.4;
                margin-bottom: 1em;
            }

            &.toggle p.field-description {
                margin-bottom: 0;
            }

            &.DateTime, &.Date {
                z-index: 100;
            }
        }
    }

    #property-bar-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;

        .svg-wrapper {
            width: 1.75em;
        }

        h2 {
            margin: 0;
            margin-left: 0.875rem;
        }
    }

    form {
        padding: 1.5em 3em 3em 3em;
    }
}

#side-bar.sidebar-visible {
    &:not(.open) {
        & + #property-bar.sidebar-visible {
            &:not(.open) {
                & + #workspace {
                    width: calc(100% - 7em);
                }
            }

            &.open {
                & + #workspace {
                    width: calc(75% - 4em);
                }
            }
        }
    }

    &.open {
        & + #property-bar.sidebar-visible {
            &:not(.open) {
                & + #workspace {
                    width: calc(85% - 3em);
                }
            }

            &.open {
                & + #workspace {
                    width: 60%;
                }
            }
        }
    }
}

.app-logo {
    max-height: 4.5em;
    text-align: center;

    div {
        height: 100%;
        max-height: 4.5em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg, img {
        max-height: 100px;
        max-width: 100%;
    }

    svg path {
        fill: var(--logoFill);
    }
}

.create-link {
    position: fixed;
    bottom: 3em;
    right: 3em;
    width: 5em;
    height: 5em;
    background: var(--menuSidebarBg);
    border-radius: 100%;
    z-index: 2;
}

.plus {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    &:before, &:after {
        content: '';
        background: var(--textColorContrast);
        width: 0.125em;
        height: 50%;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
    }

    &:before {
        transform: translateX(-50%) rotate(90deg);
    }
}

.create-header {
    .svg-wrapper {
        margin-right: 2.25em;
        width: 1.75em;

        svg path {
            fill: none;
            stroke: var(--menuSidebarBg);
            stroke-width: 0.125em;
        }
    }
}

.ck-content {
    height: 400px;
}

.tooltip {
    opacity: 1 !important;

    .tooltip-inner {
        border-radius: 10px !important;
        background: var(--svgBaseColor) !important;
    }
}

.vdatetime-popup {
    z-index: 10000 !important;
    background: var(--svgBaseColor);

    .vdatetime-popup__header {
        background: var(--svgBaseColor);
    }

    svg path {
        fill: transparent !important;
    }

    .vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
        background: var(--svgBaseColor);
    }
}

iframe {
    border: 0;
    width: 100%;
    height: 50%;
}

th.sortable {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0.5em;
        background: #345;
        width: 0.675em;
        height: 0.125em;
        transform: translateY(50%);
    }

    &.sort-desc:after {
        border-style: solid;
        border-width: 0 6px 10px 6px;
        border-color: transparent transparent #345 transparent;
        background: none;
        width: 0;
        height: 0;
        transform: translateY(-6px);
    }

    &.sort-asc:after {
        border-style: solid;
        border-width: 10px 6px 0 6px;
        border-color: #345 transparent transparent transparent;
        background: none;
        width: 0;
        height: 0;
        transform: translateY(0px);
    }
}