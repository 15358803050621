﻿@import "variables";
@import "utilities";

@media screen and (max-width: $breakpoint-large) {
    #side-bar {
        width: 4rem;

        &.open {
            width: 4rem;
        }

        .sidebar-button {
            display: none;
        }
    }
}

@media screen and (max-width: $breakpoint-medium) {
}

@media screen and (max-width: $breakpoint-small) {
    .box-form {
        border: 0 none;
        width: 100%;
        min-width: 0;
        margin: 0;
    }
}

@media screen and (orientation: portrait) {
}

@media screen and (orientation: landscape) {
}

@media screen and (orientation: landscape) and (min-aspect-ratio: 16/9) {
}

/*IE10+ styles only go in here*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex-column > div[class*="col-"] {
        flex-basis: auto;
    }
}
