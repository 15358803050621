﻿@import "variables";
@import "utilities";

.font-base {
    font-family: $basefontstack;
    font-style: normal;
    text-rendering: optimizeLegibility;
}

.font-heading-base {
    font-family: $basefontstack;
    font-style: normal;
    text-rendering: optimizeLegibility;
}

.font-light {
    @extend .font-base;
    font-weight: 200;
}

.font-regular {
    @extend .font-base;
    font-weight: 300;
}

.font-medium {
    @extend .font-base;
    font-weight: 400;
}

.font-demi {
    @extend .font-base;
    font-weight: 500;
}

.font-bold {
    @extend .font-base;
    font-weight: 700;
}

.font-heading-light {
    @extend .font-heading-base;
    font-weight: 300;
}

.font-heading-regular {
    @extend .font-heading-base;
    font-weight: 400;
}

.font-heading-medium {
    @extend .font-heading-base;
    font-weight: 500;
}

.font-heading-bold {
    @extend .font-heading-base;
    font-weight: 700;
}

.font-size-smaller {
    @include fluid-type(8px, 10px);
}

.font-size-footer {
    @include fluid-type(10px, 12px);
}

.font-size-body-small {
    @include fluid-type(8px, 12px);
}

.font-size-body-tiny {
    @include fluid-type(8px, 10px);
}

.font-size-body {
    @include fluid-type(10px, 14px);
}

.font-size-button {
    @include fluid-type(10px, 14px);
}

.font-size-title{
    @include fluid-type(14px, 18px);
}

.font-size-subheading {
    @include fluid-type(22px, 30px);
}

.font-size-heading {
    @include fluid-type(28px, 42px);
}

.font-body-copy {
    @extend .font-regular;
    @extend .font-size-body;
}

body {
    @extend .font-regular;
    font-size: 100%;
    line-height: $line-height-normal;
    color: var(--textColor);
}

h1 {
    @extend .font-heading-base;
    @extend .font-bold;
    @extend .font-size-heading;
    margin: 0;
    margin-bottom: 0.5em;
}

h2 {
    @extend .font-heading-base;
    @extend .font-bold;
    @extend .font-size-subheading;
    margin: 0;
    margin-bottom: 0.875em;
}

h3 {
    @extend .font-heading-base;
    @extend .font-demi;
    @extend .font-size-body;
    margin: 0;
    margin-bottom: 1.1125em;
}

h4 {
    @extend .font-heading-base;
    @extend .font-demi;
    @extend .font-size-body;
    margin: 0;
    margin-bottom: 0.8125em;
}

p {
    @extend .font-light;
    @extend .font-size-body;
    margin: 0;
    margin-bottom: 2.25em;

    fieldset &{
        @extend .font-medium;
    }

    > strong, b {
        @extend .font-demi;
    }
}

a {
    @extend .font-base;
    @extend .font-demi;
    margin: 0;
}

ul, ol {
    padding-left: 2.5em;
    margin-left: -0.375em;

    li {
        padding-left: 1em;
        margin-bottom: 0.4em;
    }
}

sup{
    font-size: 60%;
}

strong{
    @extend .font-demi;
}

legend{
    @extend .font-bold;
    @extend .font-size-title;
    margin-bottom: 0.5em;
}

