﻿// Colors should be themed

$basefontstack: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;

// Typography
$line-height-normal: 1.375em;
$line-height-narrow: 1.2em;
$line-height-hero: 1.2em;

$header-padding: 4.375em;
$section-padding: 7.25em;

$animation-fast: 0.25s;
$animation-slow: 1s;
$animation-normal: 0.5s;

// Breakpoints
$breakpoint-largest: 1441px;
$breakpoint-large: 960px;
$breakpoint-medium: 640px;
$breakpoint-small: 480px;
$breakpoint-smallest: 321px;


//form field global variables
$toggleWidth: 3.25em;
$toggleHeight: 1.75em;
$toggleInactiveBG: #D8D8D8;
$toggleButtonBG: #fff;
$toggleSpacing: 0.15em;




/**     box-services variables     **/
$line-size: 2rem;
$box-border-size: 0.125rem;

/**     sidebar variables     **/
$sidebar-animation-time: 0.75s;

$article-stylesheet-map:(
    theme--c360: "https://hpe-assets.cdn.channel360.net/articles/styles.css"
);



@mixin fluid-type($min-size: 12px, $max-size: 16px) {
    $min-vw: $breakpoint-small;
    $max-vw: $breakpoint-largest;

    font-size: $min-size;

    @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-size} + #{(($max-size / ($max-size * 0 + 1)) - ($min-size / ($min-size * 0 + 1)))} * ( (100vw - #{$min-vw}) / #{(($max-vw / ($max-vw * 0 + 1)) - ($min-vw / ($min-vw * 0 + 1)))}));
    }

    @media screen and (min-width: $max-vw) {
        font-size: $max-size;
    }
}

@function get-article-stylesheet-url($theme-name: "theme--c360"){
    @if map-has-key($article-stylesheet-map, $theme-name){
        @return map-get($article-stylesheet-map, $theme-name);
    } @else {
        @return "https://hpe-assets.cdn.channel360.net/articles/styles.css";
    }
}